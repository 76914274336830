<template>
  <div class="volunteer">
    <div class="volunteer-continar">
     <TitleMore
      :title="['冠名医疗机构']"
      :isMore="false"
    ></TitleMore>
    <div
      class="baseCard"
    >
      <div
        class="itemText"
        v-for="item in medicalData"
        :key="item.id"
		style="margin-bottom: 10px;"
        @click="$goto('/detailShow',{id:item.id})"
      >
        <p>
          <img
            src="../../assets/img/list-little.png"
            alt=""
          >
          <span class="itemTextContent">{{item.title}}</span>
        </p>

      </div>
      
      <el-pagination
        @current-change="handleCurrentChange"
        :current-page.sync="page"
        :page-size="size"
        :total="total"
        layout="total, prev, pager, next, jumper"
      >
      </el-pagination>
    </div>
    </div>
  </div>
</template>

<script>
import TitleMore from "@components/TitleMore.vue";
import apiUrls from '@api'
export default {
  components: {
    TitleMore
   },
  name: 'Volunteer',
  data () {
    return {
      medicalData: [],
      page: 0,
      size: 10,
      total: 0,
    }
  },
  created () {
    this.getselfDyList("xxgk_gmyljg");
  },
  methods: {
    getselfDyList(type) {
      apiUrls.selfDynamicList({ node: type, size:this.size,page:this.page }).then((res) => {
        this.medicalData = res.results.data;
        this.total = res.results.pager.totalElements
      });
    },
    handleCurrentChange(val) {
      this.page=val
      this.getselfDyList()
    },
  }
}
</script>

<style lang="less" scoped>
.volunteer {
  background-color: #f5f5f5;
    .volunteer-continar{
        width: 1200px;
        min-height: 400px;
        padding-bottom: 40px;
        margin: 0 auto;
        background-color: #f5f5f5;
        &::before {
            display: block;
            content: ".";
            clear: both;
            height: 0;
            overflow: hidden;
            visibility: hidden;
        }
    }
	.redtitle  {
	    cursor: pointer;
	    margin-top: 10px;
	    font-size: 23px;
	    color: #333333;
	    font-weight: bold;
	    display: flex;
	    justify-content: space-between;
	    align-items: center;
	    margin-bottom: 16px;
	    span {
	        &:nth-child(1) {
	        display: flex;
	        &::before {
	        content: "";
	        display: block;
	        height: 20px;
	        width: 20px;
	        background: url('../../assets/img/title.png') no-repeat center center;
	       }
	     }
		}
	}
	.projectintroduct {
	    height: 30px;
	    background-color: #ffffff !important;
	    overflow: hidden;
	    padding: 40px 23px 40px 40px;
	    box-shadow: 0px 0px 18px 0px rgba(14,14,14, 0.13);
		display: flex;
		justify-content: space-between;
		align-items: center;
		font-size: 16px;
		a {
			text-decoration: none;
			color: #333333;
		}
		p {
			color: #ABABAB;
		}
	}
}
</style>
